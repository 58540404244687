import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import { useAppSelector } from '../../store/hooks';
import { Link, useNavigate } from 'react-router-dom';

interface Props {
  contain?: () => Window;
}

const drawerWidth = 240;
const navItems = ['Featured', 'Scenes'];
const boxStyle: React.CSSProperties = { display: 'flex' };

export const Header: React.FC<Props> = ({ contain }) => {

  const { scenes } = useAppSelector(state => state.scene);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [searchCriteria, setSearchCriteria] = React.useState("");
  const history = useNavigate();

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <div onClick={handleDrawerToggle}>
      <h3 style={{ textAlign: 'center' }}>Scenes</h3>
      <hr style={{ width: '80%', height: '1px', backgroundColor: 'gray', border: 'none' }} />
      <List>
        {scenes.map((item) => (
          <ListItem key={item.name} disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }}>
              <a href={window.location.origin + item.url.toLowerCase()}>{item.icon} {item.name}</a>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </div>
  );

  const container = contain !== undefined ? () => contain().document.body : undefined;

  return (
    <div style={boxStyle}>
      <CssBaseline />
      <AppBar position="fixed" component="nav" color="transparent" style={{ boxShadow: 'none', color: 'white', padding: '0 100px' }}>
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
          {/* Left Section: Menu Icon and Nav Items */}
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <IconButton color="inherit" aria-label="open drawer" edge="start" onClick={handleDrawerToggle} sx={{ mr: 2 }}>
              <MenuIcon />
            </IconButton>
            {navItems.map((item) => (
              <Button key={item} sx={{ color: '#fff', fontSize: '1.3rem', fontWeight: 'bold' }}>
                <Link to={`/home#${item}`}>{item}</Link>
              </Button>
            ))}
          </div>

          {/* Right Section: Search Input and Login Button */}
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {/* <input
              type="text"
              className="search-input"
              placeholder="Search"
              value={searchCriteria}
              onChange={(e) => setSearchCriteria(e.target.value)}
              onFocus={() => history("/search")}
            /> */}
            <Button color="inherit" sx={{ fontSize: '1.3rem', fontWeight: 'bold', marginLeft: '10px' }}>
              Login
            </Button>
          </div>
        </Toolbar>
      </AppBar>

      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, backgroundColor: 'black', color: 'white' },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </div>
  );
};
