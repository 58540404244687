import { useNavigate } from "react-router-dom";
import { Scene  } from "../../store/scenes";
import { OnlineTracker } from "../LandingPage/components/OnlineTracker";
import { Header } from "../LandingPage/Header";
import "./ScenePages.css";
import {  useState } from "react";
import { Chip } from "@mui/material";

type Props = {
    scene: Scene;
};

export const ScenePage: React.FC<Props> = ({ scene }) => {

    const history = useNavigate();
    const pills = scene.category.split(",");
    const [mainImage, setMainImage] = useState(scene.images[0]); 
    const [miniImages, setMiniImages] = useState(scene.images.slice(1)); 
  
   
    const handleImageClick = (clickedImage: string) => {
      const newMiniImages = miniImages.map((img) =>
        img === clickedImage ? mainImage : img
      );
  
      setMainImage(clickedImage);
      setMiniImages(newMiniImages);
    };

    return (
        <div>
            <Header />
            <div className="scene-info-container">
            <hr style={{ height: '1px', backgroundColor: 'gray', border: 'none', width: '100%', margin: 0 }} />
                <div className="scene-info-left">
                    <div className="scene-info-row">
                        <div className="scene-info-logo">
                            {scene.logo ? (
                                <img src={scene.logo} alt={scene.name} height={scene.name ==="Nugs" ? 280 : 300} />
                            ) : <h1 className={`video-title ${scene.className}`} style={{paddingBottom: '150px', paddingTop:'50px', paddingLeft: '40px'}}>{scene.name}</h1>}
                        </div>

                        <div className="scene-info-card">
                            <h3 style={{color: "gray"}}>Release Date: <span style={{color: "white"}}>{scene.releaseDate}</span></h3>
                            <div style={{color: "gray", display: 'flex', gap: '10px'}}>
                                Tags:
                            {
                                pills.map(pill => {
                                    return <Chip label={pill} variant="outlined" color="info"/>
                                })
                            }
                            </div>
                            
                        </div>
                    </div>

                    <div className="scene-info-text-container">
                        <div className="scene-info-title-container">
                            <h1>{scene.name}</h1>
                            <OnlineTracker count={10} className="scene-info-online-tracker" />
                        </div>
                        
                        <p>{scene.info}</p>
                    </div>
                    <div className="scene-jump-in-container">
                    <button className="jump-in-button" onClick={() => history(scene.url)}>
                        Jump In
                    </button>
                </div>
                </div>
                <div className="scene-info-images">
                    <div className="scene-info-master-image">
                    <img src={mainImage} alt={`${scene.name} Main`} height={500} />
                    </div>

                    <div className="scene-info-mini-images">
                    {miniImages.map((image, index) => (
                        <img
                        key={index}
                        src={image}
                        alt={`${scene.name} Mini ${index + 1}`}
                        onClick={() => handleImageClick(image)}
                        style={{ cursor: "pointer" }}
                        />
                    ))}
                    </div>

                </div>
               
            </div>
        </div>
    );
};
