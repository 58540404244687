import { useState } from "react"
import { useAppSelector } from "../../store/hooks"
import scenes, { Scene } from "../../store/scenes"
import { SceneCard } from "../SceneCards/SceneCard"

export const SearchPage:React.FC = () => {
    
    const {scenes} = useAppSelector(state => state.scene)
    const [sceneArray, setSceneArray] = useState<Scene[]>(scenes);
    const [searchCriteria, setSearchCriteria] = useState("");

    function setCarouselViaSearch(event: any, array: Scene[], searchCriteria: string) {
        if (event.key === 'Enter') {
          if (searchCriteria.trim()) {
            const filteredScenes = array.filter(scene =>
              scene.name.toLowerCase().includes(searchCriteria.toLowerCase())
            );
            console.log(filteredScenes);
            setSceneArray(filteredScenes);
          } else {
            setSceneArray(scenes);
          }
        }
    }

    return(
        <>
        <div style={{margin: 'auto', textAlign: 'center', padding: '50px'}}>
        <input
                type="text"
                className="search-input"
                placeholder="Search"
                value={searchCriteria}
                onChange={(e) => setSearchCriteria(e.target.value)}
                onKeyDown={(e) => setCarouselViaSearch(e, scenes, searchCriteria)}
                />
        </div>
           
            <div id="Scenes" style={{marginTop: '20px', padding: '10px', color: 'white'}}>
            <div className="scene-card-manager">
                    {
                        sceneArray.map(scene => (
                            <SceneCard key={scene.name} scene={scene} carousel={false} className="scene-card"/>
                        ))
                    }
                </div>
            </div>
        </>
    )
}