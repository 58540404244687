import React, { useState } from "react";
import { Avatar } from "@readyplayerme/visage";
import { useSelector } from "react-redux";
import { useAppSelector } from "../store/hooks";

type LoaderProps = {
  loadingPercentage: number
}

export const Loader: React.FC<LoaderProps> = ({loadingPercentage}) => {

  const {player} = useAppSelector((state) => state.appUser)
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  const circleScalingFactor = Math.min(windowSize.width / 1800, windowSize.height / 1800);

  return (
    <>
      <div className='loader-container'>
        <div className='loader-avatar'>
          <Avatar modelSrc={player.glbUrl ? player.glbUrl : "https://models.readyplayer.me/6641ec98e6d7b4328f134d69.glb"} idleRotation={true} cameraInitialDistance={2.5} />
        </div>
        <div className='loader-content'>
          <span style={{ height: 200 * circleScalingFactor, width: 200 * circleScalingFactor }} className="loader"></span>
          <p style={{ fontSize: 'large', color: "white" }}>Loading... ({loadingPercentage}%)</p>
        </div>
      </div>
    </>
  );
  
}
