import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Footer } from "../LandingPage/Footer";

export const FAQPage:React.FC = () => {


    return(
        <>
        <h1 className="heading" style={{color: 'white', margin: 'auto', textAlign:'center', padding: '20px'}}>FAQ</h1>
        <div className="faq">
        <Accordion >
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
            style={{backgroundColor: "gray", color: "white"}}
            >
            What is PartyVerse
            </AccordionSummary>
            <AccordionDetails>
            PartyVerse is an immersive, interactive platform that brings gamified experiences to life within the metaverse. 
            Built using Unity, PartyVerse combines cutting-edge technology with engaging social interactions to create a vibrant
            virtual world where users can explore, play, and connect.

            In PartyVerse, you can join a variety of themed environments—each with its own unique games, events, and social spaces. 
            Whether you're looking to challenge friends in a competitive sports game, dance to live music at a virtual concert, 
            or simply hang out in a beautifully crafted digital space, PartyVerse offers something for everyone. 
            The platform emphasizes social engagement, enabling users to communicate, collaborate, and compete in real time.

            With regular updates, new experiences, and seasonal events, PartyVerse is constantly evolving to offer fresh content that 
            keeps users coming back. Whether you're a gamer, social butterfly, or just curious about exploring the metaverse, 
            PartyVerse is your gateway to an endless array of interactive experiences.
            </AccordionDetails>
        </Accordion>
        <Accordion>
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
            style={{backgroundColor: "gray", color: "white"}}
            >
            How do I join a game or event in PartyVerse?
            </AccordionSummary>
            <AccordionDetails>
            Joining a game or event in PartyVerse is easy! Once you're logged into the platform, 
            you can browse through a variety of themed spaces and activities available. Simply navigate to the "Explore" section, 
            where you'll find a list of ongoing games, events, and experiences. Click on any that interest you, 
            and you'll be seamlessly transported to that virtual space. You can also join friends directly if they're already in a game,
            making it easy to connect and play together in real-time.
            </AccordionDetails>
        </Accordion>
        <Accordion >
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3-content"
            id="panel3-header"
            style={{backgroundColor: "gray", color: "white"}}
            >
            Is PartyVerse free to use, or do I need to purchase a subscription?
            </AccordionSummary>
            <AccordionDetails>
            PartyVerse is free to join and explore! You can access a wide range of games, social spaces, and events without any cost. 
            </AccordionDetails>
        </Accordion>
    </div>
    </>
    )
}