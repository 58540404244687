import { useState, useEffect } from "react";
import { AvatarDisplay } from "./AvatarDisplay";
import { useSelector, useDispatch } from "react-redux";
import Cookies from "js-cookie";
import { useNavigate } from 'react-router-dom';
import { Trail } from "./TrailText";

import styles from '../../styles.module.css'
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setPlayer } from "../../store/appUser";
import { cleanMessage } from "../LiveChat/chatWindowComponents/ChatBubble";
import { ColourPicker } from "./ColourPicker";
import { Diversity1 } from "@mui/icons-material";

interface WindowSize {
    width: number,
    height: number
}

type TextState = {
    text1: boolean,
    text2: boolean,
    shown: boolean
}
export const AvatarPage: React.FC = () => {

    const history = useNavigate();
    let dispatch = useDispatch();
    const [windowSize, setWindowSize] = useState<WindowSize>({
        width: window.innerWidth,
        height: window.innerHeight
      });
    const {player} = useAppSelector((state) => state.appUser)
    const {loader} = useAppSelector((state) => state.scene)
    const [open, set] = useState<TextState>({text1: false, text2: false, shown: false})
    const [prompt, setprompt] = useState<string>("")

    // useEffect(() => {
    //     let cookie = Cookies.get("avatarUrl")
    //     if(cookie){
    //         dispatch(setAvatarGlb(cookie))
    //         handleShowAvatar(cookie)
    //     }
    // })
    
    useEffect(() => {
        const handleResize = () => {
          setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight
          });
        };
    
        window.addEventListener('resize', handleResize);
    
        return () => window.removeEventListener('resize', handleResize);
    }, []);


    function handleShowAvatar(glb: string){
        let stateToUpdate = {
            ...player,
            glbUrl: glb, 
        };
        dispatch(setPlayer(stateToUpdate))
        //Cookies.set("avatarUrl", glb);
    }

     function handleJumpIn(){
        if(player.glbUrl !== null && player.displayName !== null){
            if( player.displayName.length === 0){
                setprompt("prompt")
                setTimeout(() => {
                    setprompt("")
                }, 2000)
            } else {
                history("/mv/unity")
            }
        } else {
            setprompt("prompt")
            setTimeout(() => {
                setprompt("")
            }, 2000)
        }
    }

    function handleAvatarName(name:string){
        let clean = cleanMessage(name)
        const urlRegex = /(https?:\/\/[^\s]+|www\.[^\s]+)/g;
        if(urlRegex.test(clean)){
            clean = clean.replace(urlRegex, "*")
        }
        let stateToUpdate = {
            ...player,
            displayName: clean, 
        };
        dispatch(setPlayer(stateToUpdate))
        Cookies.set("username", clean)
    }


    return (
    <div className="hero-image">
        <div className="header avatar-heading">
            <h1 className="heading avatar-heading" style={{marginBottom: '100px'}}>Avatar Selection</h1>
        </div>
        <div className="avatar-page">
            {/* Left Column with Inputs */}
            <div className="avatar-input-column">
                <div className={`avatar-input-area1 ${prompt}`}>
                    <h3 className="headings avatar-heading" style={{textAlign: 'center'}}>Enter your Name:</h3>
                    <div>
                        <input
                            className="search-input"
                            placeholder="Enter A Name"
                            style={{
                                padding: "20px 50px",
                                margin: "20px"
                            }}
                            value={player.displayName ? player.displayName : ""}
                            onChange={(e) => {
                                handleAvatarName(e.target.value);
                            }}
                        />
                    </div>
                </div>

                <div className={`avatar-input-area2`}>
                    <h4 className="headings avatar-heading" style={{textAlign: 'center'}}>Default Avatars:</h4>
                    <div style={{ display: 'flex', alignItems: 'center', width: '100%', textAlign: 'center', justifyContent: 'space-between' }}>
                        <button
                            className="join-button"
                            onClick={() => handleShowAvatar("https://models.readyplayer.me/6641ec2fd5d11dcc57df141d.glb")}
                        >
                            Female
                        </button>
                        <button
                            className="join-button"
                            onClick={() => handleShowAvatar("https://models.readyplayer.me/66c870b093f9fe518a518f81.glb")}
                        >
                           Male
                        </button>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', alignContent: 'center', textAlign: 'center'}}>
                    <h4 className="headings avatar-heading">Or Create Your Own:</h4>
                        <div style={{ marginTop: '5%', width: '100%' }}>
                            <button className="join-button" onClick={() => history("/mv/rpm")}>
                                Enter Editor
                            </button>
                        </div>
                        <h4 className="headings avatar-heading">Choose a Colour:</h4>
                        <ColourPicker position='fixed' top='50%' right='60%' buttonClass='join-button' />
                    </div>
                    
                </div>            
            </div>
            <div className="avatar-display-container">
                <div className="avatar-container">                
                    <AvatarDisplay />
                </div>

                <div className={`avatar-input-area2`}>
                    <div style={{ width: '100%', textAlign: 'center' }}>
                        <button className="button-85" onClick={() => handleJumpIn()}>Jump In</button>
                    </div>
                </div>
            </div>
            
        
        </div>
        <div className="overlay-hero"></div>
    </div>

    )
}