import React, { useEffect, useState } from 'react';
import { VideoCarousel } from './VideoCarousel';
import "./styles/Landing.css";
import { HeadlineCardsManager } from '../HeadlineCards/HeadlineCardsManager';
import { Header } from './Header';
import { Footer } from './Footer';
import { AboutSection } from './AboutSection';
import { useLocation } from 'react-router-dom';

const WelcomePage: React.FC = () => {

    const { hash } = useLocation();

    useEffect(() => {
        if (hash) {
        const element = document.querySelector(hash);
        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
        }
        }
    }, [hash]);

    return(
        <div className='App'>  
            <Header />   
            <VideoCarousel/>
            <div className='headline-card-container'>
                <HeadlineCardsManager />
            </div>
            <AboutSection title='What We Do' 
            description='Welcome to a unique digital platform where you can explore immersive Unity experiences, 
            all from the comfort of your browser. Dive into a variety of interactive environments, 
            from vibrant sports bars and lively music venues to engaging gaming rooms. 
            Whether you are here to compete in virtual sports, enjoy live music, 
            or connect with friends in dynamic social spaces, our platform brings the excitement of real-world entertainment to your screen. 
            Discover endless possibilities, meet new people, and enjoy a fresh way to experience entertainment online—anytime, anywhere.' 
            imagePath='/images/hexMan.png'
            flip={false}
            cssClassName="about-section"
            />            
            <Footer additionalClasses={''}  home={false}/>
        </div>
    )
}

export default WelcomePage;
