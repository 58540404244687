import { createSlice} from "@reduxjs/toolkit";

interface SceneState {
    name: string,
    moduleId: string,
    loader: string,
    data: string,
    framework: string,
    code:string,
    streamingAssests: string,
    scenes: Scene[]
}

export interface Style {
    primary_color: string; 
    secondary_color: string; 
    accent_color: string; 
    highlight_color: string; 
    error_color: string; 
    background: string;
    text:string
}

export interface Scene{
    name: string,
    moduleId: string,
    image: string,
    loader: string | undefined,
    data: string | undefined,
    framework: string | undefined,
    code:string | undefined,
    streamingAssests: string | undefined,
    style: Style
    video: string
    url: string
    blurb: string
    icon: string
    category: string
    className: string
    logo: string | null
    carousel: boolean
    infoPageUrl:string
    info:string
    images: string[]
    releaseDate: string
}

const initialState: SceneState = {
    name: "",
    moduleId: "",
    loader: "",
    data: "",
    framework: "",
    code:  "",
    streamingAssests: "",
    scenes: [
        {
            name: "Nugs",
            moduleId: "f39d4d5d-a9d5-4d2b-b77a-9ce883767819",
            image: "/images/nugs/audience.jpg",
            loader: process.env.REACT_APP_UNITY_BUILD_FILE_LOADER_NUGS,
            data: process.env.REACT_APP_UNITY_BUILD_FILE_DATA_NUGS,
            framework: process.env.REACT_APP_UNITY_BUILD_FILE_FRAMEWORK_NUGS,
            code:process.env.REACT_APP_UNITY_BUILD_FILE_WASM_NUGS,
            streamingAssests: process.env.REACT_APP_UNITY_BUILD_FILE_STREAMING_ASSETS_NUGS,
            style: {
                primary_color: "#660000", 
                secondary_color: "#990033",  
                accent_color: "#5F021F", 
                highlight_color: "#FF9000",  
                error_color: "#8C001A", 
                background: "/images/nugs/concert.jpg",
                text: "white"
            },
            video: "/video/nugs.mp4",
            url: "/mv/nugs",
            blurb: "A dynamic venue where live music fills the air, creating a perfect spot for music lovers to gather and enjoy performances.",
            icon: " 🎶 ",
            category: "Social, Music",
            className: "nugs",
            logo: "https://www.nugs.net/on/demandware.static/Sites-NugsNet-Site/-/default/dw37d1b10a/images/header/logo-black.svg",
            carousel: true,
            infoPageUrl: "/nugs/info",
            info: "Step into Nugs, a virtual venue where the power of live music comes alive. As you enter, you're greeted by the vibrant energy of a packed audience, swaying to the rhythm of the latest hits and timeless classics. This is a place where music lovers gather to experience unforgettable live performances from their favorite artists. The immersive lighting and dynamic stage setup create a concert atmosphere that feels as real as any physical venue. \n\nBut Nugs isn't just about the music—it's a social hub where you can meet fellow fans, share your passion for different genres, and make new friends. Whether you're dancing by the stage or chilling in the lounge area, there's always something happening to keep you entertained. Get ready to lose yourself in the music and let the beat guide your night in this electrifying environment.",
            images: ["/images/nugs/nugsImageOne.png","/images/nugs/nugsImageTwo.png","/images/nugs/nugsImageThree.png",],
            releaseDate: "15/11/2024"
        },
        {
            name: "Hoops",
            moduleId: "fdb86d38-6f4c-43f5-b9cd-403caaa3b790",
            image: "/images/hoops/basketball.jpg",
            loader: process.env.REACT_APP_UNITY_BUILD_FILE_LOADER_HOOPS,
            data: process.env.REACT_APP_UNITY_BUILD_FILE_DATA_HOOPS,
            framework: process.env.REACT_APP_UNITY_BUILD_FILE_FRAMEWORK_HOOPS,
            code:process.env.REACT_APP_UNITY_BUILD_FILE_WASM_HOOPS,
            streamingAssests: process.env.REACT_APP_UNITY_BUILD_FILE_STREAMING_ASSETS_HOOPS,
            style: {
                primary_color: "#F35B04", 
                secondary_color: "#F18701",  
                accent_color: "#F7B801", 
                highlight_color: "#7678ED",  
                error_color: "#3D348B", 
                background: "/images/hoops/basketballBackground.jpg",
                text: "grey"
            },
            video: "/video/hoops.mp4",
            url: "/mv/hoops",
            blurb: "Step into a vibrant bar centered around basketball, featuring engaging games and a lively sports atmosphere perfect for fans.",
            icon: " 🏀 ",
            category: "Social, Gaming, Sports",
            className: "hoops",
            logo: "/images/hoops/image.png",
            carousel: true,
            infoPageUrl: "/hoops/info",
            info: "Welcome to Hoops, a sports bar where the love for basketball meets social gaming. From the moment you walk in, you're surrounded by the sights and sounds of the court—buzzers, cheers, and the unmistakable swish of the net. Play interactive basketball games with friends or join a tournament to prove your skills. Whether you're shooting solo or going head-to-head with others, every shot counts in this competitive yet friendly atmosphere.\n\nBut Hoops is more than just a game—it’s a community for basketball enthusiasts. Catch live sports broadcasts, discuss the latest games, and enjoy themed events that bring fans together. The vibrant decor and sporty vibe make it the perfect spot to relax, have fun, and celebrate your passion for basketball. Come for the games, stay for the camaraderie.",
            images: ["/images/hoops/hoopsImageOne.png","/images/hoops/hoopsImageTwo.png","/images/hoops/hoopsImageThree.png"],
            releaseDate: "15/11/2024"
        },
        {
            name: "The 19th Hole",
            moduleId: "19b58188-ed65-43d8-a835-1f2aa3e99d4b",
            image: "/images/golf/golfer.jpg",
            loader: process.env.REACT_APP_UNITY_BUILD_FILE_LOADER_GOLF,
            data: process.env.REACT_APP_UNITY_BUILD_FILE_DATA_GOLF,
            framework: process.env.REACT_APP_UNITY_BUILD_FILE_FRAMEWORK_GOLF,
            code:process.env.REACT_APP_UNITY_BUILD_FILE_WASM_GOLF,
            streamingAssests: process.env.REACT_APP_UNITY_BUILD_FILE_STREAMING_ASSETS_GOLF,
            style: {
                primary_color: "#30362F", 
                secondary_color: "#625834",  
                accent_color: "#A59132", 
                highlight_color: "#FFFBDB",  
                error_color: "#DA7422", 
                background: "/images/golf/golf.jpg",
                text: "black"
            },
            video: "/video/golf.mp4",
            url: "/mv/golf",
            blurb: "A golf lover’s retreat where you can play and relax, blending the challenge of golf with a friendly bar atmosphere.",
            icon: "⛳ ",
            category: "Social, Gaming, Sports",
            className: "golf",
            logo: "/images/golf/golfLogo.png",
            carousel: true,
            infoPageUrl: "/golf/info",
            info: "The 19th Hole is the ultimate golfer’s paradise, blending the challenges of the fairway with the relaxation of a top-notch bar. Whether you’re looking to perfect your swing on the virtual greens or unwind after a game, this venue has it all. Play a round of golf with friends, or take on a solo challenge as you navigate beautifully rendered courses designed to test your skills. It's a haven for those who love the sport, offering a casual yet immersive experience.\n\nBeyond the game, The 19th Hole offers a warm, inviting atmosphere where you can kick back with a drink and share stories of your best shots. The cozy setting is perfect for socializing with fellow golf enthusiasts or enjoying a quiet evening with friends. Whether you're here for the game or the social scene, you'll find plenty of reasons to keep coming back.",
            images: ["/images/golf/golfImageOne.png", "/images/golf/golfImageTwo.png","/images/golf/golfImageThree.png"],
            releaseDate: "15/11/2024"
        },
        {
            name: "Champions",
            moduleId: "f97244e2-16d0-4f0d-91cb-883611db0233",
            image: "/images/champions/boxing.jpg",
            loader: process.env.REACT_APP_UNITY_BUILD_FILE_LOADER_CHAMPIONS,
            data: process.env.REACT_APP_UNITY_BUILD_FILE_DATA_CHAMPIONS,
            framework: process.env.REACT_APP_UNITY_BUILD_FILE_FRAMEWORK_CHAMPIONS,
            code:process.env.REACT_APP_UNITY_BUILD_FILE_WASM_CHAMPIONS,
            streamingAssests: process.env.REACT_APP_UNITY_BUILD_FILE_STREAMING_ASSETS_CHAMPIONS,
            style: {
                primary_color: "#E3170A", 
                secondary_color: "#E16036",  
                accent_color: "#D6A99A", 
                highlight_color: "#D6CBC1",  
                error_color: "#CDD6D0", 
                background: "/images/champions/boxing-glove.jpg",
                text: "white"
            },
            video: "/video/champions.mp4",
            url: "/mv/champions",
            blurb: "Enter a bar dedicated to the sport of boxing, featuring games and a thrilling atmosphere for fight enthusiasts.",
            icon: " 🥊 ",
            category: "Social, Gaming, Sports",
            className: "champions",
            logo: "/images/champions/championsLogo.png",
            carousel: false,
            infoPageUrl: "/champions/info",
            info: "Step into the exhilarating world of Champions, a bar dedicated to the thrill of boxing. As soon as you enter, you're surrounded by the raw energy of the ring—flashing lights, roaring crowds, and the anticipation of the next big match. Test your skills in various boxing-themed games or simply soak in the atmosphere as you cheer on friends. The venue is designed to capture the spirit of classic boxing matches, offering an experience that's both intense and entertaining.\n\nBut Champions isn’t just for the hardcore fans. It's a place where everyone can enjoy the excitement of the sport, whether you're here for the games, the drinks, or the company. The decor is inspired by the golden age of boxing, with vintage posters and memorabilia adding to the authentic vibe. It's the perfect spot to unleash your inner champion or simply enjoy a night out in a unique setting.",
            images: ["/images/champions/championsImageOne.png","/images/champions/championsImageTwo.png","/images/champions/championsImageThree.png"],
            releaseDate: "15/11/2024"
        },
        {
            name: "Tennis",
            moduleId: "f97244e2-16d0-4f0d-91cb-883611db0233",
            image: "/images/tennis/tennis.jpg",
            loader: process.env.REACT_APP_UNITY_BUILD_FILE_LOADER_TENNIS,
            data: process.env.REACT_APP_UNITY_BUILD_FILE_DATA_TENNIS,
            framework: process.env.REACT_APP_UNITY_BUILD_FILE_FRAMEWORK_TENNIS,
            code:process.env.REACT_APP_UNITY_BUILD_FILE_WASM_TENNIS,
            streamingAssests: process.env.REACT_APP_UNITY_BUILD_FILE_STREAMING_ASSETS_TENNIS,
            style: {
                primary_color: "#008000",
                secondary_color: "#FFD700",
                accent_color: "#F5F5DC",
                highlight_color: "#FFFFFF",
                error_color: "#FF4C4C",
                background: "/images/tennis/tennis-court.jpg",
                text: "white"
            },
            video: "/video/tennis.mp4",
            url: "/mv/tennis",
            blurb: "Enjoy a unique bar experience surrounded by tennis-themed games and décor, where fans can play and unwind.",
            icon: " 🎾 ",
            category: "Social, Gaming, Sports",
            className: "tennis",
            logo: null,
            carousel: false,
            infoPageUrl: "/tennis/info",
            info: "At Tennis, the love of the game is celebrated in every corner. Step onto the court-themed bar, where you can challenge friends to fast-paced matches or take part in doubles tournaments. The interactive games capture the thrill of a real tennis match, complete with realistic sounds and dynamic visuals. Whether you're serving up aces or just rallying for fun, this venue is a must-visit for tennis enthusiasts.\n\nBeyond the game, Tennis offers a relaxed yet vibrant atmosphere perfect for socializing. Enjoy themed cocktails, mingle with fellow fans, or simply unwind in a space that celebrates the elegance of the sport. With its bright decor and tennis-inspired ambiance, it’s a great place to gather, play, and celebrate your love for the game.",
            images: ["/images/tennis/tennisImageOne.png","/images/tennis/tennisImageTwo.png","/images/tennis/tennisImageThree.png"],
            releaseDate: "15/11/2024"
        },
        {
            name: "Hit The Bar!",
            moduleId: "f97244e2-16d0-4f0d-91cb-883611db0233",
            image: "/images/football/football.jpg",
            loader: process.env.REACT_APP_UNITY_BUILD_FILE_LOADER_SOCCER,
            data: process.env.REACT_APP_UNITY_BUILD_FILE_DATA_SOCCER,
            framework: process.env.REACT_APP_UNITY_BUILD_FILE_FRAMEWORK_SOCCER,
            code:process.env.REACT_APP_UNITY_BUILD_FILE_WASM_SOCCER,
            streamingAssests: process.env.REACT_APP_UNITY_BUILD_FILE_STREAMING_ASSETS_SOCCER,
            style: {
                primary_color: "#006400",
                secondary_color: "#FFFFFF",
                accent_color: "#FFD700",
                highlight_color: "#8A2BE2",
                error_color: "#FF4C4C",
                background: "/images/football/football-pitch.jpg",
                text: "white"
            },
            video: "/video/football.mp4",
            url: "/mv/football",
            blurb: "Immerse yourself in the excitement of soccer with this bar that offers interactive games and an ambiance full of team spirit.",
            icon: "⚽ ",
            category: "Social, Gaming, Sports",
            className: "soccer",
            logo: "/images/football/hitTheBarLogo.png",
            carousel: false,
            infoPageUrl: "/football/info",
            info: "Immerse yourself in the world of Soccer, a bar where the spirit of the game comes alive. The venue is designed to capture the excitement of a stadium, complete with interactive soccer games that let you score the winning goal or defend the net like a pro. Challenge your friends to mini-matches or team up for tournaments, all while soaking in the lively atmosphere filled with cheers and chants.\n\nBut Soccer isn’t just about the games; it's a social experience for fans of the world's most popular sport. Gather with fellow soccer lovers to watch live matches, share your favorite moments, and celebrate the beautiful game. Themed decor and a vibrant ambiance make it the perfect spot for game nights, whether you're here to play or simply enjoy the camaraderie of fellow fans.",
            images: ["/images/football/footballImageOne.png","/images/football/footballImageTwo.png","/images/football/footballImageThree.png"],
            releaseDate: "15/11/2024"
        },
        {
            name: "The Hex",
            moduleId: "f97244e2-16d0-4f0d-91cb-883611db0233",
            image: "/images/hex/hex.jpg",
            loader: process.env.REACT_APP_UNITY_BUILD_FILE_LOADER_HEX,
            data: process.env.REACT_APP_UNITY_BUILD_FILE_DATA_HEX,
            framework: process.env.REACT_APP_UNITY_BUILD_FILE_FRAMEWORK_HEX,
            code:process.env.REACT_APP_UNITY_BUILD_FILE_WASM_HEX,
            streamingAssests: process.env.REACT_APP_UNITY_BUILD_FILE_STREAMING_ASSETS_HEX,
            style: {
                primary_color: "#FF1493",
                secondary_color: "#00FFFF",
                accent_color: "#8A2BE2",
                highlight_color: "#FFFF00",
                error_color: "#FF0000",
                background: "/images/hex/hex-bg.jpg",
                text: "white"
            },
            video: "/video/hex.mp4",
            url: "/mv/hex",
            blurb: "A modern music venue offering live performances and an electrifying vibe that draws in music lovers from all around.",
            icon: " 🎤 ",
            category: "Social, Music",
            className: "hex",
            logo: "/images/hex/hexLogo.png",
            carousel: false,
            infoPageUrl: "/hex/info",
            info: "The Hex is a cutting-edge music venue where the pulse of live performances meets the energy of a modern nightclub. Step into a world of neon lights, futuristic visuals, and electrifying beats that draw you onto the dance floor. Whether you’re here for the hottest DJs or up-and-coming bands, this venue promises a night filled with unforgettable music and an atmosphere that’s out of this world.\n\nBeyond the stage, The Hex offers a social scene like no other. Mingle with fellow music lovers, explore themed rooms, or simply lose yourself in the immersive light shows and interactive experiences. It’s a place where creativity and music collide, making every visit a new adventure. Get ready to be dazzled by the sights and sounds of this unique virtual venue.",
            images: ["/images/hex/hexImageOne.png","/images/hex/hexImageTwo.png","/images/hex/hexImageThree.png"],
            releaseDate: "15/11/2024"
        },
        {
            name: "The Jazz Club",
            moduleId: "f97244e2-16d0-4f0d-91cb-883611db0233",
            image: "/images/jazz/jazz.jpg",
            loader: process.env.REACT_APP_UNITY_BUILD_FILE_LOADER_JAZZ,
            data: process.env.REACT_APP_UNITY_BUILD_FILE_DATA_JAZZ,
            framework: process.env.REACT_APP_UNITY_BUILD_FILE_FRAMEWORK_JAZZ,
            code:process.env.REACT_APP_UNITY_BUILD_FILE_WASM_JAZZ,
            streamingAssests: process.env.REACT_APP_UNITY_BUILD_FILE_STREAMING_ASSETS_JAZZ,
            style: {
                primary_color: "#2F4F4F",
                secondary_color: "#8B4513",
                accent_color: "#DAA520",
                highlight_color: "#FFFFFF",
                error_color: "#B22222",
                background: "/images/jazz/jazz-bg.jpg",
                text: "white"
            },
            video: "/video/jazz.mp4",
            url: "/mv/jazz",
            blurb: "Experience the rich sounds of live jazz in an elegant setting that captures the essence of a classic jazz club.",
            icon: " 🎷 ",
            category: "Social, Music",
            className: "jazz",
            logo: null,
            carousel: false,
            infoPageUrl: "/jazz/info",
            info: "Transport yourself to an era of elegance and sophistication at The Jazz Club. The soft glow of dimmed lights, the rich sounds of a live jazz band, and the clinking of cocktail glasses set the scene for a night of classic charm. Whether you’re a jazz aficionado or just looking to unwind, this venue captures the essence of a timeless jazz club with its warm, inviting ambiance.\n\nBut the experience doesn’t stop at the music. The Jazz Club is also a social haven where you can sip on craft cocktails, chat with friends, and soak in the atmosphere. With its vintage decor and cozy seating, it’s the perfect place to relax and let the smooth melodies wash over you. Step into a world of sophistication and enjoy a night of musical magic.",
            images: ["/images/jazz/jazzImageOne.png","/images/jazz/jazzImageTwo.png","/images/jazz/jazzImageThree.png"],
            releaseDate: "15/11/2024"
        }
    ]
}

export const sceneStateSlice = createSlice({
    name:"scenes",
    initialState,
    reducers: {
        setSceneState: (state, action) => {
            state = action.payload
        },
        setName: (state, action) => {
            state.name = action.payload
        },
        setModuleId: (state, action) => {
            state.moduleId = action.payload
        },
        setLoader: (state, action) => {
            state.loader = action.payload
        },
        setData: (state, action) => {
            state.data = action.payload
        },
        setFramework: (state, action) => {
            state.framework = action.payload
        },
        setCode: (state, action) => {
            state.code = action.payload
        },
        setStreamingAssets: (state, action) => {
            state.streamingAssests = action.payload
        }
    }
});

export const { setName, setModuleId, setCode,setData,setFramework,setLoader,setSceneState,setStreamingAssets } = sceneStateSlice.actions;

export default sceneStateSlice.reducer;