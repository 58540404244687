export const AboutPage:React.FC = () => {


    return(
        
        <><h1 className="heading"  style={{ textAlign: 'center', color: 'white', marginTop: '20px' }}>About Us</h1>
        <div className="about-section">
            <h3 className="heading" style={{ textAlign: 'center' }}>PartyVerse</h3>
            <div className="content">
                <img src={"/images/hexMan.png"} alt="About" />
                <p>Welcome to PartyVerse, where the boundaries between the digital and real worlds blur to create a vibrant
                    and immersive virtual experience. Founded with a vision to bring people together in innovative ways,
                    PartyVerse is more than just a platform—it's a universe of endless possibilities.
                    Using the latest in Unity technology, we craft interactive environments that let users explore,
                    play, and socialize in ways that feel dynamic and engaging. Our mission is to redefine how people connect
                    online, offering a space where fun, creativity, and community thrive.</p>
            </div>
            <div className="content">
                <p>At the heart of PartyVerse is our commitment to creating experiences that are not only entertaining but
                    also deeply interactive. Our virtual spaces are carefully designed to captivate the senses,
                    offering everything from competitive sports games and music venues to social lounges and themed events.
                    Each environment is a meticulously crafted digital playground where you can challenge your friends in a
                    game of basketball, dance to live music, or explore new virtual worlds. We believe in the power
                    gamification to transform simple interactions into memorable adventures.</p>
                <img src={"/images/concert.jpg"} alt="About" />
            </div>
            <div className="content">
                <img src={"/images/golf.jpg"} alt="About" />
                <p>Our team is passionate about the metaverse and the endless opportunities it presents for social engagement.
                    We are a diverse group of creators, developers, and visionaries dedicated to pushing the boundaries of
                    what's possible in the virtual space. By leveraging advanced game design and interactive technology,
                    we strive to deliver a seamless user experience that is both fun and intuitive. At PartyVerse,
                    we understand that community is at the core of any great experience, which is why we focus on fostering
                    connections that go beyond the screen.</p>
            </div>
            <div className="content">
                <p>Looking ahead, we're excited to continue expanding PartyVerse with new features, environments,
                    and seasonal events that keep our community engaged and coming back for more. We are constantly evolving,
                    driven by feedback from our users and the latest trends in digital innovation. Whether you're a gamer
                    looking for your next challenge, a music lover ready to attend a virtual concert, or simply someone
                    curious about exploring new worlds, PartyVerse is your gateway to an ever-expanding universe of digital
                    experiences. Join us, and discover where the Party never ends!</p>
                <img src={"/images/music.png"} alt="About" />
            </div>
        </div></>
    )
}